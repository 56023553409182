<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="11" class="white rounded-xl">
      <v-tabs :color="color.primaryVariant" v-model="tab">
        <v-tab>
          <span class="wc-caption-1">Administrar Membresías</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">Miembros</span>
        </v-tab>
        <v-tab v-if="user.role == 'HEAD_OFFICE'">
          <span class="wc-caption-1">Formulario de Registro</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Affiliations></Affiliations>
        </v-tab-item>

        <v-tab-item>
          <Members></Members>
        </v-tab-item>

        <v-tab-item v-if="user.role == 'HEAD_OFFICE'">
          <Registration></Registration>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Chat from "@/components/shared/Chat.vue";

import { colors } from "@/constants/colors";
import Affiliations from "../Affiliations.vue";
import Members from "../Members/index.vue";
import Registration from "../Registration.vue";
import { mapState } from "vuex";
export default {
  name: "MyMembers",
  components: {
    Members,
    Affiliations,
    Registration,
    Chat,
  },
  data() {
    return {
      color: colors,
      tab: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = "62b622c66a7441ae20159d7d";
      const ampi2 = "6297b0fec7bf8b6f826313be";
      if (this.user.role === "BRANCH") {
        return this.user.headOffice === ampi1 || this.user.headOffice === ampi2;
      }
      return this.user._id === ampi1 || this.user._id === ampi2;
    },
  },
};
</script>

<style>
</style>