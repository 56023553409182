<template>
  <div style="background: #00946b" class="flexpers">
    <v-container style="margin-top: auto; margin-bottom: auto">
      <div>
        <v-card rounded="xl">
          <v-row class="my-auto" style="background: #fff">
            <v-col
              style="background: #f8fafc"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-btn
                    style="float: left"
                    :color="
                      homologateStatusWithColor('ACTIVE').color
                    "
                    width="auto"
                    :loading="load"
                    class="
                      font-weight-black
                      mx-auto
                      text-capitalize
                      font-weight-bold
                      rounded-pill
                    "
                  >
                    ACTIVO
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 pb-4 text-center">
                  <v-avatar color="#fbb406" size="100" class="mx-auto mt-4">
                    <span
                      style="color: white; font-weight: bold; font-size: 20px"
                      >Test</span
                    >
                  </v-avatar>
                  <v-card-title
                    class="px-0 py-1 align-center justify-center"
                    style="
                      font-size: 1.2rem;
                      word-break: break-word;
                      font-weight: 600;
                    "
                  >
                    testName testLastname
                  </v-card-title>
                  <hr class="hr_color mt-5" />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex py-1">
                    <v-icon color="black" class="pr-2"> mdi-domain </v-icon>
                    <span class="h5">
                      Test Company
                    </span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-telefono.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">
                      N/D
                    </span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-email.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">
                      test@email.com
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              style="background: #fff"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row class="pr-5">
                <v-col :cols="12" width="100%" elevation="0">
                  <v-card-title class="px-0 font-weight-black"></v-card-title>
                  <v-row :cols="12" width="100%" elevation="0">
                    <v-col
                      :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'"
                      v-for="(field, i) in memberData"
                      :key="i"
                      class="pt-3 pb-2"
                      :class="showField(field) ? '' : 'd-none'"
                    >
                      <p>
                        <span style="font-weight: 800">{{ field.name }}: </span>
                        <br />
                        <small>N/D</small>
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="hr_color" />
                </v-col>

                <v-col cols="12" class="text-end">
                  <span class="px-0 font-weight-black upercase pr-6"
                    >MIEMBRO DE</span
                  >
                  <v-avatar size="100" class="hr_color mx-auto">
                    <span
                      style="color: white; font-weight: bold; font-size: 20px"
                    >
                      Test Company
                    </span>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { formatDate } from "@/utils/dates";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";

export default {
  name: "DataSheetPrev",
  props: {
    memberData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cred: {},
      credInfo: {},
      personalInfo: [],
      directions: [],
      asociado: [],
      profile: "",
      backId: "",
      load: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("microsite", ["getMemberInformation"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    homologateStatusWithColor,
    showField(field) {
      if(field.showInDataSheet != undefined) {
        if(field.fieldType=='DOC'){
          return false;
        }else{
          return field.showInDataSheet;
        }
      }else{
        if(field.fieldType=='DOC'){
          return false;
        }else{
          return true;
        }
      }
    },
  },
};
</script>


<style scoped>
.flexpers {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}
.v-main__wrap {
  background: aliceblue !important;
}
.hr_color {
  border: 1px solid #c4c6c8;
}
.upercase {
  text-transform: uppercase !important;
}
</style>