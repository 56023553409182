<template>
  <v-row class="mt-12 mx-6">
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          fixed
          fab
          bottom
          right
          color="blue darken-2"
          class="mb-16"
          @click="prevDataSheet = true"
        >
          <v-icon color="white"> mdi-card-account-details-outline </v-icon>
        </v-btn>
      </template>
      <span>Previsualizar Ficha tecnica</span>
    </v-tooltip>

    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          fixed
          fab
          bottom
          right
          color="amber darken-2"
          @click="prevCredential = true"
        >
          <v-icon color="white"> mdi-qrcode-scan </v-icon>
        </v-btn>
      </template>
      <span>Previsualizar Credencial Virtual</span>
    </v-tooltip>

    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <p class="headline font-weight-bold">
            Configuración del formulario de registro
          </p>
        </v-col>
      </v-row>
      <v-divider class="mb-10" />
      <v-row justify="center">

        <v-col v-if="extraFields.length > 0" cols="12" class="pt-0">

          <v-row
            v-for="(field, index) in extraFields"
            :key="index"
            justify="center"
          >
            <v-col cols="12" class="d-flex justify-end">
              <v-dialog
                v-if="!field.new && !field.edit"
                v-model="field.deleteDialog"
                width="300"
              >
                <template #activator="{ on, attrs }">
                  <icon-action-button
                    tooltip="Eliminar"
                    color="red"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-trash-can-outline
                  </icon-action-button>
                </template>
                <v-card>
                  <v-card-title class="text-h5"
                    >Eliminar campo extra</v-card-title
                  >
                  <v-divider />
                  <v-card-text class="text-justify my-5">
                    {{
                      field.new
                        ? "¿Seguro que no quiere guardar este campo?"
                        : `¿Seguro de que quiere eliminar el campo "${field.name}"?`
                    }}
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="grey"
                      text
                      @click="field.deleteDialog = false"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="red"
                      text
                      @click="deleteField(field)"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <icon-action-button
                v-if="field.new || field.edit"
                tooltip="Cancelar"
                color="red"
                @click="cancelAction(field)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                mdi-close
              </icon-action-button>
              <icon-action-button
                v-if="field.new || field.edit"
                :tooltip="
                  (field.new && 'Guardar') || (field.edit && 'Actualizar')
                "
                color="green"
                @click="saveField(field)"
                :disabled="
                !isFieldValid ||
                !user.permissions.Membership ||
                !user.permissions.Membership.update ||
                disableField(field)
              "
              >
                mdi-content-save-outline
              </icon-action-button>
              <icon-action-button
                v-if="!field.new && !field.edit"
                tooltip="Editar"
                color="accent"
                @click="editField(field)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                mdi-pencil-outline
              </icon-action-button>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <p class="font-weight-bold">Tipo de campo</p>
              <v-radio-group
                :mandatory="false"
                v-model="field.fieldType"
                class="ml-2"
                :disabled="!field.edit && !field.new"
              >
                <v-radio
                  color="primary"
                  label="Campo de texto"
                  value="TEXT"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Opción múltiple"
                  value="LIST"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Documento"
                  value="DOC"
                ></v-radio>
                <v-radio color="primary" label="Fecha" value="DATE"></v-radio>
                <v-radio
                  color="primary"
                  label="Número"
                  value="NUMBER"
                ></v-radio>

                <template v-if="false">
                  <p class="font-weight-medium">Avanzadas</p>
                  <v-radio
                    color="primary"
                    label="Lista de países"
                    value="COUNTRY"
                  ></v-radio>
                </template>
              </v-radio-group>
              <p class="font-weight-bold">Opciones</p>
              <v-switch
                v-model="field.required"
                class="ml-2"
                color="primary"
                label="¿Obligatorio?"
                hide-details="auto"
                :disabled="!field.edit && !field.new"
              ></v-switch>
              <v-switch
                v-model="field.showInCredential"
                class="ml-2"
                color="primary"
                label="Mostrar en la credencial virtual"
                hide-details="auto"
                :disabled="!field.edit && !field.new"
              ></v-switch>
              <v-switch
                v-model="field.showInDataSheet"
                class="ml-2"
                color="primary"
                label="Mostrar en la ficha técnica"
                hide-details="auto"
                :disabled="!field.edit && !field.new"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="4">
              <p class="font-weight-bold">Configuración</p>
              <v-text-field
                label="Nombre del campo o pregunta"
                v-model="field.name"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>
              <v-text-field
                label="Descripción"
                v-model="field.description"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>

              <v-combobox
                v-model="field.options"
                v-if="field.fieldType === 'LIST'"
                hide-selected
                hint="Agrega las opciones de tu campo."
                label="Opciones del campo"
                multiple
                persistent-hint
                small-chips
                filled
                rounded
                :disabled="!field.edit && !field.new"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Presiona
                        <kbd>enter</kbd> para crear una nueva opción.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-combobox
                v-if="field.fieldType === 'DOC'"
                v-model="field.accept"
                :items="fileExtensions"
                item-text="text"
                item-value="value"
                hide-selected
                hint="Agrega los formatos permititdos para este campo. O agrega el que necesites ej: .pdf, .docx, .jpg"
                label="Formatos permitidos del campo"
                multiple
                persistent-hint
                small-chips
                filled
                rounded
                :disabled="!field.edit && !field.new"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Presiona
                        <kbd>enter</kbd> para agregar el formato.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>

              <p class="font-weight-bold">Permisos</p>
              <v-row justify="center">
                <v-col cols="6">
                  <v-checkbox
                    v-model="field.permissions"
                    :disabled="!field.edit && !field.new"
                    label="Organizacion Nacional"
                    color="red"
                    value="HEAD_OFFICE"
                    hide-details
                  />
                  <v-checkbox
                    v-model="field.permissions"
                    :disabled="!field.edit && !field.new"
                    label="Organizacion Local"
                    color="red"
                    value="BRANCH"
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="field.permissions"
                    :disabled="!field.edit && !field.new"
                    label="Miembro"
                    color="red"
                    value="MEMBER"
                    hide-details
                  />
                  <v-checkbox
                    v-model="field.permissions"
                    :disabled="!field.edit && !field.new"
                    label="Sub Miembro"
                    color="red"
                    value="MEMBERSHIP_USER"
                    hide-details
                  />
                </v-col>



              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="4">
              <p class="font-weight-bold">Vista previa</p>
              <FormInput :index="index" :field="field" @input="inputHandler" />
            </v-col>
            <v-col cols="12" class="my-5">
              <v-divider />
            </v-col>
          </v-row>

        </v-col>
        <h3 v-else class="my-10">No se ha creado ningún campo extra</h3>
      </v-row>
      <v-row
        class="pb-7"
        v-if="
          user.permissions.Membership &&
          user.permissions.Membership.update == true
        "
      >
        <v-col
          cols="12"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex justify-end'
              : 'd-flex justify-center'
          "
        >
          <action-button @click="addField" iconLeft icon="mdi-plus">
            Nuevo campo
          </action-button>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :value="prevDataSheet||prevCredential">
      <template v-slot:default="dialog">
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown != true"
          @click="dialog.value = false, closePrev()"
          bottom
          right
          absolute
          fab
          color="accent"
          :style="{
            position: 'absolute',
            bottom: '50px',
            right: '50px',
          }"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn v-else @click="dialog.value = false, closePrev()" large bottom
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <DataSheetPrev :memberData="extraFields" v-if="prevDataSheet"/>
        <CredentialPrev :memberData="extraFields" v-if="prevCredential"/>
      </template>
    </v-dialog>
  </v-row>
</template>
<script>
import qs from "query-string";
import { mapMutations, mapActions, mapState } from "vuex";
import FormInput from "@/components/shared/FormInput.vue";
import ActionButton from "@/components/shared/ActionButton.vue";
import ModalTemplate from "@/components/shared/ModalTemplate";
import IconActionButton from "@/components/shared/IconActionButton.vue";
import Endpoints from "@/share/Endpoints";
import { formatExtraFields } from "@/utils";
import Vue from "vue";
import DataSheetPrev from "../../components/dashboard/previsualization/DataSheetPrev.vue";
import CredentialPrev from '../../components/dashboard/previsualization/CredentialPrev.vue';

export default {
  components: {
    ActionButton,
    IconActionButton,
    ModalTemplate,
    FormInput,
    DataSheetPrev,
    CredentialPrev
  },
  props: ["affiliationId"],
  data: () => ({
    
    isFieldValid: true,
    prevDataSheet: false,
    prevCredential: false,
    affiliationLevel: {
      items: [],
      selected: null,
    },
    extraFields: [],
    fileExtensions: [
      {
        text: "PDF",
        value: ".pdf",
      },
      {
        text: "Word",
        value: ".docx",
      },
      {
        text: "Imagen",
        value: ".jpg, .jpeg, .png, .gif, .bmp, .svg",
      },
      {
        text: "Excel",
        value: ".xlsx",
      },
      {
        text: "Power Point",
        value: ".pptx",
      },
    ],
  }),
  async created() {
    const response = await this.getRegistrationFields(this.user._id);
    if (response.status) {
      this.extraFields = response.data.data;
    }

    const fieldsWithOrder = this.extraFields.filter(field => field.order != null);
    const fieldsWithoutOrder = this.extraFields.filter(field => field.order == null);

    fieldsWithOrder.sort((a, b) => a.order - b.order);

    let orderedFields = [...fieldsWithoutOrder];

    for (const field of fieldsWithOrder) {
      const position = field.order - 1; 
      orderedFields.splice(position, 0, field);
    }

    this.extraFields = orderedFields;

  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("registrationFields", [
      "getRegistrationFields",
      "addRegistrationField",
      "updateRegistrationField",
      "deleteRegistrationField",
    ]),
    minValueRule(value) {
    const isValid = value >= 1;
    return isValid || 'El valor mínimo es 1';
  },
    closePrev() {
      this.prevDataSheet = false;
      this.prevCredential = false;
    },
    inputHandler({ index, value }) {
      console.log(index, value);
    },
    formatAccept(accept) {
      if (!accept) return "";
      let acceptString = "";
      accept.forEach((item) => {
        acceptString += item.value + ", ";
      });
      return acceptString;
    },
    async createField(field) {
      delete field.new;
      const { _id: temporaryId, ...restFieldProps } = field;
      const payload = {
        ...restFieldProps,
        formattedName: formatExtraFields(field.name),
      };
      const response = await this.addRegistrationField(payload);
      if (response.status) {
        const { registrationField } = response.data;
        this.extraFields = this.extraFields.map((ticket) =>
          ticket._id === temporaryId
            ? {
                ...restFieldProps,
                ...registrationField,
                edit: false,
                id: registrationField._id,
              }
            : ticket
        );
        this.show({
          color: "primary",
          text: "Campo creado",
        });
      } else {
        this.show({
          color: "error",
          text: "Error al crear el campo",
        });
      }
    },
    async updateField(field) {
      const { _id: id, ...restFieldProps } = field;
      const payload = {
        ...restFieldProps,
        formattedName: formatExtraFields(field.name),
      };

      const res = await this.updateRegistrationField({ id, payload });
      if (res.status) {
        for (let i = 0; i < this.extraFields.length; i++) {
          if (this.extraFields[i]._id === field._id) {
            Vue.set(this.extraFields, i, {
              ...field,
              edit: false,
            });
          }
        }
        this.show({
          color: "primary",
          text: "Campo actualizado",
        });
      } else {
        this.show({
          color: "error",
          text: "Error al actualizar el campo",
        });
      }
    },
    saveField(field) {
      field?.edit && this.updateField(field);
      field?.new && this.createField(field);
    },
    editField(field) {
      Vue.set(field, "edit", true);
      field.originalData = { ...field };
      console.log(field);
    },
    cancelEdition(field) {
      this.extraFields = this.extraFields.map((auxField) =>
        auxField._id === field._id
          ? { ...field.originalData, edit: false }
          : { ...auxField }
      );
    },
    cancelAction(field) {
      field?.edit && this.cancelEdition(field);
      field?.new && this.removeField(field._id);
    },
    addField() {
      const field = {
        _id: new Date().getTime(),
        organization: this.user._id,
        fieldType: "TEXT",
        required: false,
        showInCredential: false,
        showInDataSheet: false,
        name: "",
        formattedName: "",
        description: "",
        options: [],
        accept: [],
        value: "",
        new: true,
        permissions: ["HEAD_OFFICE", "BRANCH", "MEMBER", "MEMBERSHIP_USER"],
      };
      this.extraFields.push({ ...field, deleteDialog: false });
    },
    async deleteField(field) {
      const { _id: fieldId, new: newField } = field;
      if (!newField) {
        await this.axios.delete(
          Endpoints.extraFieldsModifyField.replace(":fieldId", fieldId)
        );
        this.show({
          color: "primary",
          text: "Campo eliminado correctamente",
        });
      }
      this.removeField(fieldId);
      field.deleteDialog = false;
    },
    removeField(fieldId) {
      this.extraFields = this.extraFields.filter(
        (field) => field._id !== fieldId
      );
    },
    disableField(field) {
      const { originalData, ...rest } = field;
      const isFormWithoutChanges =
        JSON.stringify(originalData) === JSON.stringify(rest);
      return isFormWithoutChanges;
    },
  },
};
</script>