<template>
  <div style="background: #808080" class="flexpers">
    <v-container style="margin-top: auto; margin-bottom: auto">
      <v-row class="text-center ma-3" justify="center">
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7" class="pa-0">
          <v-card
            elevation="2"
            class="d-flex flex-column"
            height="100%"
            :rounded="$vuetify.breakpoint.mdAndDown ? 't-xl b-0' : 'l-xl r-0'"
          >
            <v-img
              position="center"
              class="ma-0 d-flex"
              content-class="filter-red"
              src="@/assets/images/landing/memberLanding/background.png"
            >
              <v-card
                height="100%"
                color="transparent"
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'd-flex flex-column justify-center'
                    : 'd-flex flex-column pa-5'
                "
              >
                <v-card-title
                  :class="
                    $vuetify.breakpoint.mdAndDown
                      ? 'justify-center'
                      : 'justify-left'
                  "
                  class="wDarkerBlue--text ma-0"
                >
                  <p
                    class="ma-0"
                    :style="
                      $vuetify.breakpoint.mdAndDown
                        ? 'font-size:15px; word-break: break-word'
                        : 'font-size:17px  word-break: break-word'
                    "
                  >
                    CREDENCIAL VIRTUAL
                  </p>
                  <!-- Botón de descarga -->
                  <!-- <v-btn class="ml-3 brown lighten-5" fab small :elevation="0">
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn> -->
                </v-card-title>
                <v-card-text
                  class="wDarkerBlue--text mt-5"
                  :style="
                    $vuetify.breakpoint.smAndDown
                      ? 'font-size:17px'
                      : 'font-size:15px'
                  "
                >
                  <v-row
                    class="pb-4 mx-0 h-100"
                    align="start"
                    justify="space-between"
                  >
                    <v-avatar color="#1c2434" size="120">
                      <v-img
                        src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0="
                      ></v-img>
                    </v-avatar>
                    <v-btn
                      class="btnstyle subtitle-2 font-weight-black pa-6 d-flex justify-center primary-variant"
                      :loading="load"
                      :color="homologateStatusWithColor('ACTIVE').color"
                    >
                      ACTIVO
                    </v-btn>
                  </v-row>
                  <v-row>
                    <div class="my-2 mx-4 text-left body-2">
                      <span
                        class="primary-variant subtitle-2 font-weight-black mayus"
                      >
                        Nombres
                      </span>
                      - Test Name
                    </div>
                    <div class="my-2 mx-4 text-left body-2">
                      <span
                        class="primary-variant subtitle-2 font-weight-black mayus"
                      >
                        Apellidos
                      </span>
                      - Test Lastname
                    </div>
                    <div class="my-2 mx-4 text-left body-2">
                      <span
                        class="primary-variant subtitle-2 font-weight-black mayus"
                      >
                        Nivel de membresía
                      </span>
                      - General
                    </div>
                    <div
                      v-for="(field, i) in memberData"
                      :key="i"
                      class="my-2 mx-4 text-left body-2"
                      :class="showField(field) ? '' : 'd-none'"
                    >
                      <div v-if="field.fieldType != 'DOC'">
                        <span
                          class="primary-variant subtitle-2 font-weight-black mayus"
                        >
                          {{ field.name }}
                        </span>
                        - N/D
                      </div>
                    </div>
                  </v-row>
                </v-card-text>

                <v-card-actions
                  :class="
                    $vuetify.breakpoint.mdAndDown
                      ? 'justify-center'
                      : 'justify-end'
                  "
                  class="d-flex"
                >
                </v-card-actions>
              </v-card>
            </v-img>
          </v-card>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pa-0">
          <v-card
            height="100%"
            elevation="2"
            class="pt2 d-flex justify-center align-center pa-4"
            :rounded="$vuetify.breakpoint.mdAndDown ? 'b-xl t-0' : 'r-xl l-0'"
          >
            <v-img
              :max-height="$vuetify.breakpoint.smAndDown ? 160 : 300"
              :max-width="$vuetify.breakpoint.smAndDown ? 160 : 300"
              class="rounded-0"
              src="https://play-lh.googleusercontent.com/lomBq_jOClZ5skh0ELcMx4HMHAMW802kp9Z02_A84JevajkqD87P48--is1rEVPfzGVf"
              content-class="filter-red"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
export default {
  name: "CredentialPrev",
  props: {
    memberData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    homologateStatusWithColor,
    showField(field) {
      if (field.showInCredential != undefined) {
        if (field.fieldType == "DOC") {
          return false;
        } else {
          return field.showInCredential;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.flexpers {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}
.v-main__wrap {
  background: aliceblue !important;
}
.hr_color {
  border: 1px solid #c4c6c8;
}
.upercase {
  text-transform: uppercase !important;
}
</style>