var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexpers",staticStyle:{"background":"#808080"}},[_c('v-container',{staticStyle:{"margin-top":"auto","margin-bottom":"auto"}},[_c('v-row',{staticClass:"text-center ma-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 7}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"elevation":"2","height":"100%","rounded":_vm.$vuetify.breakpoint.mdAndDown ? 't-xl b-0' : 'l-xl r-0'}},[_c('v-img',{staticClass:"ma-0 d-flex",attrs:{"position":"center","content-class":"filter-red","src":require("@/assets/images/landing/memberLanding/background.png")}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown
                  ? 'd-flex flex-column justify-center'
                  : 'd-flex flex-column pa-5',attrs:{"height":"100%","color":"transparent"}},[_c('v-card-title',{staticClass:"wDarkerBlue--text ma-0",class:_vm.$vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-left'},[_c('p',{staticClass:"ma-0",style:(_vm.$vuetify.breakpoint.mdAndDown
                      ? 'font-size:15px; word-break: break-word'
                      : 'font-size:17px  word-break: break-word')},[_vm._v(" CREDENCIAL VIRTUAL ")])]),_c('v-card-text',{staticClass:"wDarkerBlue--text mt-5",style:(_vm.$vuetify.breakpoint.smAndDown
                    ? 'font-size:17px'
                    : 'font-size:15px')},[_c('v-row',{staticClass:"pb-4 mx-0 h-100",attrs:{"align":"start","justify":"space-between"}},[_c('v-avatar',{attrs:{"color":"#1c2434","size":"120"}},[_c('v-img',{attrs:{"src":"https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0="}})],1),_c('v-btn',{staticClass:"btnstyle subtitle-2 font-weight-black pa-6 d-flex justify-center primary-variant",attrs:{"loading":_vm.load,"color":_vm.homologateStatusWithColor('ACTIVE').color}},[_vm._v(" ACTIVO ")])],1),_c('v-row',[_c('div',{staticClass:"my-2 mx-4 text-left body-2"},[_c('span',{staticClass:"primary-variant subtitle-2 font-weight-black mayus"},[_vm._v(" Nombres ")]),_vm._v(" - Test Name ")]),_c('div',{staticClass:"my-2 mx-4 text-left body-2"},[_c('span',{staticClass:"primary-variant subtitle-2 font-weight-black mayus"},[_vm._v(" Apellidos ")]),_vm._v(" - Test Lastname ")]),_c('div',{staticClass:"my-2 mx-4 text-left body-2"},[_c('span',{staticClass:"primary-variant subtitle-2 font-weight-black mayus"},[_vm._v(" Nivel de membresía ")]),_vm._v(" - General ")]),_vm._l((_vm.memberData),function(field,i){return _c('div',{key:i,staticClass:"my-2 mx-4 text-left body-2",class:_vm.showField(field) ? '' : 'd-none'},[(field.fieldType != 'DOC')?_c('div',[_c('span',{staticClass:"primary-variant subtitle-2 font-weight-black mayus"},[_vm._v(" "+_vm._s(field.name)+" ")]),_vm._v(" - N/D ")]):_vm._e()])})],2)],1),_c('v-card-actions',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.mdAndDown
                    ? 'justify-center'
                    : 'justify-end'})],1)],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 4}},[_c('v-card',{staticClass:"pt2 d-flex justify-center align-center pa-4",attrs:{"height":"100%","elevation":"2","rounded":_vm.$vuetify.breakpoint.mdAndDown ? 'b-xl t-0' : 'r-xl l-0'}},[_c('v-img',{staticClass:"rounded-0",attrs:{"max-height":_vm.$vuetify.breakpoint.smAndDown ? 160 : 300,"max-width":_vm.$vuetify.breakpoint.smAndDown ? 160 : 300,"src":"https://play-lh.googleusercontent.com/lomBq_jOClZ5skh0ELcMx4HMHAMW802kp9Z02_A84JevajkqD87P48--is1rEVPfzGVf","content-class":"filter-red"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }